<template>
  <div
    id="footer-section"
    class="d-flex align-items-center justify-content-center"
    :class="{
      'mt-13 h-rem-21-875': isDesktop,
      'pb-30 pt-18': isMobile
    }"
  >
    <div name="content" class="w-100 text-center">
      <a :href="instagram_url" target="_blank">
        <img 
          :width="isDesktop ? css.footerIconWidth : css.m_footerIconWidth" 
          :src="logoSrc"
        >
      </a>
    </div>

    <div name="decorations">
      <FooterDecoration />
    </div>
  </div>
</template>

<script>
import FooterDecoration from "@/components/sections/footer/FooterDecoration"

export default {
  components: { FooterDecoration },
  data() {
    return {
      css: {
        ...this.$sections.footer
      },
      instagram_url: "https://www.instagram.com/momentoprojects.id"
    }
  },
  computed: {
    logoSrc() {
      if(this.css.whiteLogo) return 'https://firebasestorage.googleapis.com/v0/b/momento-projects.appspot.com/o/master%2Fmomento_logo_white.png?alt=media'

      return 'https://firebasestorage.googleapis.com/v0/b/momento-projects.appspot.com/o/master%2Fmomento_logo.png?alt=media'
    }
  }
}
</script>