<template>
  <div id="opening-decoration" :style="styles">
    <template v-if="isDesktop">
      <div name="bottom-left">
        <img :src="`${iconDir}/bottom-left.png`" class="decoration" alt="bottom-left-decoration" data-aos="fade-right">
      </div>
      <div name="bottom-right">
        <img :src="`${iconDir}/bottom-right.png`" class="decoration" alt="bottom-right-decoration" data-aos="fade-left">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/opening/${this.device}`
    },
    styles() {
      const css = this.$decorations.opening

      const desktop = {
        '--bottom-left-bottom': css.bottomLeft_bottom,
        '--bottom-left-left': css.bottomLeft_left,
        '--bottom-left-width': css.bottomLeft_width,

        '--bottom-right-bottom': css.bottomRight_bottom,
        '--bottom-right-right': css.bottomRight_right,
        '--bottom-right-width': css.bottomRight_width,
      }

      return desktop
    }
  }
}
</script>

<style scoped>
  [name=bottom-left] img {
    bottom: var(--bottom-left-bottom);
    left: var(--bottom-left-left);
    width: var(--bottom-left-width);
  }

  [name=bottom-right] img {
    bottom: var(--bottom-right-bottom);
    right: var(--bottom-right-right);
    width: var(--bottom-right-width);
  }
</style>