<template>
  <div id="time-decoration" :style="styles">
    <div name="bottom-left">
      <img :src="`${iconDir}/bottom-left.png`" class="decoration" alt="bottom-left-decoration" data-aos="fade-right">
    </div>
    <div name="bottom-right">
      <img :src="`${iconDir}/bottom-right.png`" class="decoration" alt="bottom-right-decoration" data-aos="fade-left">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/time/${this.device}`
    },
    styles() {
      const css = this.$decorations.time

      const desktop = {
        '--bottom-left-bottom': css.bottomLeft_bottom,
        '--bottom-left-left': css.bottomLeft_left,
        '--bottom-left-width': css.bottomLeft_width,

        '--bottom-right-bottom': css.bottomRight_bottom,
        '--bottom-right-right': css.bottomRight_right,
        '--bottom-right-width': css.bottomRight_width,
      }

      const mobile = {
        '--m-bottom-left-bottom': css.m_bottomLeft_bottom,
        '--m-bottom-left-left': css.m_bottomLeft_left,
        '--m-bottom-left-width': css.m_bottomLeft_width,

        '--m-bottom-right-bottom': css.m_bottomRight_bottom,
        '--m-bottom-right-right': css.m_bottomRight_right,
        '--m-bottom-right-width': css.m_bottomRight_width,
      }

      return { ...mobile, ...desktop }
    }
  }
}
</script>

<style scoped>
  [name=bottom-left] img {
    bottom: var(--bottom-left-bottom);
    left: var(--bottom-left-left);
    width: var(--bottom-left-width);
  }

  [name=bottom-right] img {
    bottom: var(--bottom-right-bottom);
    right: var(--bottom-right-right);
    width: var(--bottom-right-width);
  }
</style>

<style mobile scoped>
  .mobile [name=bottom-left] img {
    bottom: var(--m-bottom-left-bottom);
    left: var(--m-bottom-left-left);
    width: var(--m-bottom-left-width);
  }

  .mobile [name=bottom-right] img {
    bottom: var(--m-bottom-right-bottom);
    right: var(--m-bottom-right-right);
    width: var(--m-bottom-right-width);
  }
</style>