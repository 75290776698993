import { render, staticRenderFns } from "./MomentoLoading.vue?vue&type=template&id=4ce41450&scoped=true&"
var script = {}
import style0 from "./MomentoLoading.vue?vue&type=style&index=0&id=4ce41450&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce41450",
  null
  
)

export default component.exports