<template>
  <div
    class="bote-container"
    :style="{
      fontFamily, 
      color: fontColor,
      ...styles
    }"
  >
    <template v-if="boteStyle == 'chat'">
      <div class="bote-chat" :style="{ fontSize: fontSizeChat,  borderColor, borderWidth }">
        <p class="name" :style="{ background: fontColorChatName }"><b>{{ name }}</b></p>
        <p class="comment mt-2-5">{{ comment }}</p>
      </div>
    </template>

    <template v-else>
      <div 
        class="bote-card" 
        :style="[
          {
            fontSize: isDesktop ? fontSizeCard : m_fontSizeCard,
            borderWidth,
            borderColor,
            maxWidth: isWide
          },
          boteCardStyles
        ]"
        :class="boteCardClass"
      >
        <template v-if="icon">
          <img
            class="bote-icon"
            :class="{
                'mb-2-5': isMobile,
                'mb-7-5': isDesktop
              }"
            :width="isDesktop ? iconWidth : m_iconWidth"
            :src="`/icons/${iconDir}/${icon}`">
          <p v-html="text"></p>
        </template>

        <slot></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["text", "icon", "boteStyle", "borderWidth", "borderColor", "name", "comment", "fontFamily", "fontColor", "fontSizeChat", "fontSizeCard", "fontFamilyB", "fontSizeB", "wide", "fontColorChatName", "m_fontSizeCard", "iconWidth", "m_iconWidth", "boteCardStyles", "boteCardClass", "m_boteCardMinHeight", "boteCardMinHeight"],
  computed: {
    isWide() {
      return typeof this.wide != "undefined" ? "unset" : ""
    },
    styles() {
      const desktop = {
        '--font-family-b': this.ffB, 
        '--font-size-b': this.ffS,
        '--bote-card-min-height': this.boteCardMinHeight
      }

      const mobile = {
        '--m-font-size-b': this.m_fontSizeB,
        '--m-bote-card-min-height': this.m_boteCardMinHeight
      }

      return { ...desktop, ...mobile  }
    }
  },
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      ffB: this.fontFamilyB || "opensansB",
      ffS: this.fontSizeB || "1rem"
    }
  }
}
</script>

<style scoped>
  .bote-container {
    font-family: opensansR;
    color: #161616;
  }

  .bote-container b {
    font-family: var(--font-family-b);
    font-size: var(--font-size-b);
  }

  .bote-container .bote-card {
    border: 0.125rem solid;
    border-style: dashed;
    border-radius: 0.625rem;
    max-width: 62.5rem;
    text-align: center;
    font-size: 1rem;
    padding: 1.875rem 2.5rem;
    min-height: var(--bote-card-min-height);
  }

  .bote-container .bote-chat {
    border: 0.125rem solid #CCCCCC;
    border-style: dashed;
    border-radius: 0.625rem;
    padding: 1.875rem 1.25rem;
    min-height: 5.688rem;
    font-size: 0.875rem;
  }

  .bote-chat {
    text-align: left;
  }

  .bote-chat p.name {
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
  }
</style>

<style mobile scoped>
 .mobile .bote-container .bote-card {
    padding: 0.938rem 1.25rem;
    min-height: var(--m-bote-card-min-height);
 }

 .mobile .bote-container .bote-chat {
   padding: 0.938rem 1.25rem;
   height: unset;
 }
</style>