const deviceAgent = {
  data() {
    return {
      device: typeof window != "undefined" ? (window.innerWidth <=900 ? "mobile" : "desktop") : null,
    }
  },
  computed: {
    isDesktop() {
      return this.device == "desktop" ? true : false
    },
    isMobile() {
      return this.device == "mobile" ? true : false
    }
  },
  methods: {
    onResize() {
        this.device = window.innerWidth <=992 ? "mobile" : "desktop"
    }
  },
  mounted() {
    if(typeof window != "undefined") window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() { 
    if(typeof window != "undefined") window.removeEventListener('resize', this.onResize)
  },
}

const deviceWindow = {
  data() {
    return {
      windowWidth: window.innerWidth
    }
  }
}

const alert = {
  methods: {
    $alertSuccess(message) {
      this.$swal({
        icon: "success",
        html: `<h5 style="color: #fff">${this.$weddingData.brideNickName} & ${this.$weddingData.groomNickName} Wedding</h5><h6 style="color: #fff">${message}</h6>`,
        showConfirmButton: true,
        confirmButtonColor: "rgb(212, 175, 55)",
        iconColor: "rgb(212, 175, 55)",
        background: this.$baseComponents.background.bgColor || "#fff",
      })
    },
    $alertFail(message = '') {
      this.$swal({
        icon: "error",
        html: `<h6 style="color: #fff">${message || 'Terjadi kesalahan. Mohon ulangi beberapa menit lagi :('}</h6>`,
        showConfirmButton: true,
        background: this.$baseComponents.background.bgColor || "#fff"
      })
    },
    $buttonClick(func) {
      if(this.isMobile) return setTimeout(() => func(), 250)

      return func()
    }
  }
}

export { deviceAgent, deviceWindow, alert }