<template>
  <div
    id="wish-section"
    :style="styles"
    :class="{
      'd-flex align-items-center justify-content-center mt-18-5': isDesktop,
      'mt-15-252 px-5': isMobile
    }"
    class="position-relative"
  >
    <div name="content" class="w-100">
      <section name="title">
        <Title :text="wishTitleText" />
      </section>

      <section
        name="wish"
        :class="{
          'd-flex justify-content-center mt-7-5': isDesktop,
          'mt-3-5': isMobile
        }"
      >
        <div
          name="wishes"
          :class="{
            'mr-6-252 pr-1-252 maxh-137-5 w-100': isDesktop,
            'maxh-rem-31': isMobile
          }"
        >
          <template v-if="wishes.length">
            <template v-for="(wish, index) in wishes">
              <BorderedText class="mb-3-752" boteStyle="chat" v-bind="wish" :key="index"/>
            </template>
          </template>
          <template v-else>
            <p class="no-data text-center">
              Belum ada ucapan.
            </p>
          </template>
        </div>

        <div
          name="form-input"
          :class="{
            'ml-7-5 w-100': isDesktop,
            'pt-7-5 mt-7-5': isMobile
          }"
        >
          <Form
            :class="{
              'pt-11-252 px-10 maxw-146 w-vw-50 h-126-252': isDesktop,
              'py-5 px-6': isMobile
            }"
          >
            <Input
              :class="{
                'mb-7-5': isDesktop,
                'mb-2-5': isMobile
              }"
              label="Nama"
              placeholder="Tulis Namamu..."
              v-model="form.name"
            />

            <TextArea
              :class="{
                'mb-7-5': isDesktop,
                'mb-3-752': isMobile
              }"
              label="Pesan"
              placeholder="Tulis Pesanmu..."
              limit="200"
              rows="8"
              v-model="form.message"
            />

            <div
              class="d-flex justify-content-center"
              :class="{
                'mt-10-252': isDesktop,
                'mb-3-752': isMobile
              }"
            >
              <Button
                :text="sendWishText" 
                @click.native="$buttonClick(submit)"
              />
            </div>
          </Form>
        </div>
      </section>
    </div>

    <div name="decorations">
      <WishDecoration />
    </div>
  </div>
</template>

<script>
import WishDecoration from "@/components/sections/wish/WishDecoration"

export default {
  components: { WishDecoration },
  props: ["wishes"],
  data() {
    return {
      form: {
        name: "",
        message: "",
        username: null
      },
      css: {
        noDataFontColor: "rgb(87, 102, 58)",
        ...this.$sections.wish
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--no-data-font-color': css.noDataFontColor
      }
      
      const mobile = {
        '--m-chat-border-bottom': css.m_chatBorderBottom
      }

      return { ...desktop, ...mobile }
    },
    sendWishText() {
      return this.isDesktop ? "Kirim Ucapan" : "Kirim"
    },
    wishTitleText() {
      return this.isDesktop ? "Ucapan untuk Kami" : "Ucapan & Doa"
    }
  },
  methods: {
    async submit() {
      const validated = this.validate()

      if(validated) {
        let res = await this.$wishService.submitWish(this.form)
  
        if(res.success) {
          this.$alertSuccess("Terimakasih atas doa dan ucapannya :)")
  
          this.resetForm()

          this.$emit("update")
        } else {
          this.$alertFail(res.message)
        }
      } else {
        this.$alertFail("Harap isi nama dan ucapan kamu")
      }
    },
    validate() {
      const excludes = ["username"]

      for(const key in this.form) {
        if(!excludes.includes(key))
        if(!this.form[key]) return false
      }

      return true
    },
    resetForm() {
      this.form = {
        name: "",
        message: "",
        username: null
      }
    }
  }
}
</script>

<style scoped>
  [name=content] {
   max-width: 75rem; 
  }
  p.desc {
    font-family: var(--desc-font-family);
    font-size: var(--desc-font-size);
    color: var(--desc-color);
  }

  [name=wishes] {
    overflow-y: auto;
  }

  [name=wishes]::-webkit-scrollbar {
    width: 0.625rem;
    opacity: 0;
  }

  [name=wishes]::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: transparent;
  }

  [name=wishes]:hover::-webkit-scrollbar-thumb {
    background: #956464;
  }

  [name=wishes]::-webkit-scrollbar-track {
    border-radius: 50px;
  }

  p.no-data {
    color: var(--no-data-font-color);
  }
</style>

<style mobile scoped>
  .mobile div[name=form-input] {
    border-top: var(--m-chat-border-bottom);
  }
</style>