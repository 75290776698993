<template>
  <div id="location-decoration" :style="styles">
    <template v-if="isDesktop">
      <div name="top-left">
        <img :src="`${iconDir}/top-left.png`" class="decoration" alt="top-left-decoration" data-aos-offset="100" data-aos="zoom-in-down">
      </div>
      <div name="top-right">
        <img :src="`${iconDir}/top-right.png`" class="decoration" alt="top-right-decoration" data-aos-offset="100" data-aos="zoom-in-down">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/location/${this.device}`
    },
    styles() {
      const css = this.$decorations.location

      const desktop = {
        '--top-left-top': css.topLeft_top,
        '--top-left-left': css.topLeft_left,
        '--top-left-width': css.topLeft_width,

        '--top-right-top': css.topRight_top,
        '--top-right-right': css.topRight_right,
        '--top-right-width': css.topRight_width,
      }

      return desktop
    }
  }
}
</script>

<style scoped>
  [name=top-left] img {
    top: var(--top-left-top);
    left: var(--top-left-left);
    width: var(--top-left-width);
  }

  [name=top-right] img {
    top: var(--top-right-top);
    right: var(--top-right-right);
    width: var(--top-right-width);
  }
</style>