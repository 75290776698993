var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"countdown-container p-5",style:(Object.assign({}, {backgroundColor: _vm.backgroundColor},
    _vm.styles))},[(_vm.time.day != null)?[_c('BorderedText',{attrs:{"wide":"","borderColor":_vm.borderColor,"borderWidth":_vm.borderWidth,"m_boteCardMinHeight":_vm.css.m_boteCardMinHeight,"boteCardMinHeight":_vm.css.boteCardMinHeight,"boteCardClass":{ 'd-flex align-items-center': _vm.isMobile }}},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[(!_vm.justMarried)?[_c('div',{staticClass:"time-wrapper",class:{
              'minw-25 maxw-25': _vm.isDesktop,
              'pl-7-5': _vm.isMobile
            }},[_c('div',{staticClass:"label"},[_c('p',[_vm._v("Hari")])]),_c('div',{staticClass:"number"},[_c('p',[_vm._v(_vm._s(_vm.time.day))])])]),_c('div',{staticClass:"time-wrapper",class:{
              'minw-25 maxw-25 px-25': _vm.isDesktop,
              'px-7-5': _vm.isMobile
            }},[_c('div',{staticClass:"label"},[_c('p',[_vm._v("Jam")])]),_c('div',{staticClass:"number"},[_c('p',[_vm._v(_vm._s(_vm.time.hour))])])]),_c('div',{staticClass:"time-wrapper",class:{
              'minw-25 maxw-25 pr-25': _vm.isDesktop,
              'pr-7-5': _vm.isMobile
            }},[_c('div',{staticClass:"label"},[_c('p',[_vm._v("Menit")])]),_c('div',{staticClass:"number"},[_c('p',[_vm._v(_vm._s(_vm.time.minute))])])]),_c('div',{staticClass:"time-wrapper",class:{
              'minw-25 maxw-25': _vm.isDesktop,
              'pr-7-5': _vm.isMobile
            }},[_c('div',{staticClass:"label"},[_c('p',[_vm._v("Detik")])]),_c('div',{staticClass:"number"},[_c('p',[_vm._v(_vm._s(_vm.time.second))])])])]:[_c('img',{attrs:{"src":("/icons/" + _vm.iconDir + "/" + _vm.justMarriedIcon),"width":_vm.justMarriedIconWidth}})]],2)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }